<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
      <ServerError v-if="ServerError" />
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Categories
                  <v-spacer></v-spacer>
                  <!-- <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        solo
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col> -->
                  <!-- <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Category</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <ImageComp
                                    :singleImage="image"
                                    @stepper="winStepper"
                                    :heading="'Upload Banner Image'"
                                    :componentType="'bannerImage'"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="name"
                                    label="Category Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <span>Add Description</span>
                                </v-col>
                                <v-col cols="12">
                                  <div id="app">
                                    <vue-editor
                                      v-model="description"
                                    ></vue-editor>
                                  </div>
                                </v-col>
                                <v-col cols="12">
                                  <ImageComp
                                    :singleImage="image"
                                    @stepper="winStepper"
                                    :heading="'Upload Image'"
                                    :componentType="'categoryImage'"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="categoryadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row> -->
                </v-card-title>
                <v-data-table :headers="headers" :items="category" :search="search" hide-default-footer
                  class="elevation-1">
                  <template v-slot:[`item.image`]="{ item }">
                    <img v-bind:src="mediaURL + item.image" width="auto" height="50px" />
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="info(item._id)">
                      mdi-eye
                    </v-icon>
                    <v-icon small class="mr-2" @click="editcat(item)">
                      mdi-pencil
                    </v-icon>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mr-2" v-on="on" v-bind="attrs" color="red">
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title>Are you sure you want to delete this
                          Category?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="item.delete = false">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItem(item)">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <v-dialog v-model="editdialog" :retain-focus="false" max-width="600px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Category </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <ImageComp :singleImage="editingitem.bannerImage" @stepper="winStepper"
                                  :heading="'Upload Banner Image'" :componentType="'bannerImage'" />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field v-model="editingitem.name" label="Category Name"></v-text-field>
                              </v-col>
                              <!-- <v-col cols="12">
                                <span>Edit Description</span>
                              </v-col> -->
                              <v-col cols="12">
                                <!-- <div id="app">
                                  <vue-editor
                                    v-model="editingitem.description"
                                  ></vue-editor>
                                </div> -->
                                <v-textarea outlined rows="10" v-model="editingitem.description"
                                  label="Category Description"></v-textarea>
                              </v-col>
                              <v-col cols="12">
                                <ImageComp :singleImage="editingitem.image" @stepper="winStepper"
                                  :heading="'Upload Image'" :componentType="'categoryImage'" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="editdialog = false">
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <!-- <div class="pt-2 blue-grey lighten-5">
                    <v-pagination
                      :length="perPage"
                      :total-rows="totalData"
                      v-model="currentPage"
                    ></v-pagination>
                  </div> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
// import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    ImageComp,
    // VueEditor,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: {},
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      description: null,
      categoryImg: null,
      isConsulting: false,
      bannerImage: "",
      categoryImage: "",
      cId: "",
      image: "",
      attachments: null,
      attachmentsformData: new FormData(),
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Image", value: "image" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "categoryImage") {
        this.categoryImage = window_data.selectedFiles;
      }
      if (window_data.type == "bannerImage") {
        this.bannerImage = window_data.selectedFiles;
      }
    },
    deleteItem(cat) {
      var data = {};
      data["categoryId"] = cat._id;
      axios({
        url: "/course/category/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;

          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      // data["isConsulting"] = this.isConsulting;
      data["description"] = this.description;
      axios({
        url: "/course/category/add",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var id = response.data.data._id;
            if (this.bannerImage) {
              this.uploadImage(id);
            }
            if (this.categoryImage) {
              this.uploadCatImage(id);
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["description"] = this.editingitem.description;
      axios({
        url: "/course/category/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.bannerImage) {
              this.uploadImage(this.editingitem._id);
            }
            if (this.categoryImage) {
              this.uploadCatImage(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.bannerImage);

      axios({
        method: "POST",
        url: "/category/upload/banner/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            formData = new FormData();
            this.bannerImage = "";
            location.reload();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCatImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.categoryImage);

      axios({
        method: "POST",
        url: "/category/upload/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            formData = new FormData();
            this.categoryImage = "";
            location.reload();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(scid) {
      this.$router.push("/categoryView?id=" + scid);
    },
  },
};
</script>
  
<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
  